<template>
  <v-sheet class="surface">
    <v-card flat class="">
      <v-data-table
          :headers="headers"
          :items="licenceData"
          no-results-text="No check details are currently held on your profile."
          no-data-text="No check details are currently held on your profile."
      >
        <template v-slot:item.groupId="{ item }">
          <div class="">{{ getGroupName(item.groupId) }}</div>
        </template>
        <template v-slot:item.completionDate="{ item }">
          <div>{{ item.completionDate | moment('DD/MM/YYYY') }}</div>
        </template>
        <template v-slot:item.expires="{ item }">
          <div>{{ getExpiryDate(item.completionDate, item.groupCheckId) }}</div>
        </template>
        <template v-slot:item.status="{ item }">
          <v-icon :color="getItemStatusColor(item.groupCheckId,item.completionDate)">
            {{ getItemStatusColor(item.groupCheckId,item.completionDate) === 'red' ? 'mdi-close-circle': 'mdi-check-circle' }}
          </v-icon>
        </template>
        <template v-slot:item.actions="{ item }">
          <app-btn x-small fab color="secondary" v-if="isEditable" @click="remove(item)">
            <v-icon>mdi-delete</v-icon>
          </app-btn>
        </template>

        <template v-slot:footer>
          <v-divider class="my-5"/>
          <v-sheet class="background " elevation="0" v-if="isEditable">
            <v-form ref="formAmend">
              <v-container>
                <v-row>
                  <v-col cols="12">
                    <div class="text-body-1">Enter a New Item.</div>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="0" sm="0" md="0" lg="1"></v-col>
                  <v-col cols="12" sm="12" md="12" lg="4">
                    <div class="caption">Check/Signature</div>
                    <div>
                      <app-autocomplete
                          outlined
                          dense
                          v-model="selectedItem"
                          :value="scopedGroupChecks"
                          item-value="name"
                          item-text="name"
                          :items="items"
                          clearable
                          @change="getCurrencyVerifiersList"
                      >
                        <template v-slot:selection="data">
                          <div class="text-body-2">
                            {{ selectedItem ? getGroupName(data.item.groupId) : '' }} {{ data.item.name }}
                          </div>
                        </template>
                        <template v-slot:item="data">
                          <div class="text-body-2">
                            {{ getGroupName(data.item.groupId) }} {{ data.item.name }}
                          </div>
                        </template>
                      </app-autocomplete>
                    </div>
                  </v-col>
                  <v-col cols="12" sm="5" md="6" lg="2" class="ml-lg-n6">
                    <div class="caption">Confirmed by</div>
                    <div v-if="!newItemCanSelfCertify">
                      <app-autocomplete
                          v-model="selectedPerson"
                          :items="scopedCurrencyVerifiersList"
                          dense
                          outlined
                          clearable
                          Label="Select checker"
                          item-text="name"
                          item-value="_id"
                          item-disabled="disabled"
                          :disabled="false"
                          :rules="[value => !!value || 'Required.']"
                      />
                    </div>
                    <div v-else>Self</div>
                  </v-col>
                  <v-col cols="12" sm="5" md="4" lg="3" class="ml-lg-n8">
                    <div class="caption px-2">Date completed</div>
                    <div>
                      <app-date-picker label="Completion Date" v-model="selectedCompletionDate" v-show="selectedItem"/>
                    </div>
                  </v-col>
                  <v-spacer/>
                  <v-col cols="12" sm="2" md="2" lg="2" class="justify-end">
                    <div class="caption mx-3">Action</div>
                    <div class="mx-3">
                      <app-btn fab x-small color="primary" class="mr-4" @click="add" :disabled="!formValid">
                        <v-icon>mdi-plus</v-icon>
                      </app-btn>
                    </div>
                  </v-col>
                </v-row>
              </v-container>
            </v-form>
          </v-sheet>
        </template>
      </v-data-table>
    </v-card>
  </v-sheet>
</template>

<script>

import {mapActions, mapState} from "vuex";
import AppBtn from "@/components/app/Btn";
import AppDatePicker from "@/components/app/DatePicker";
import AppAutocomplete from "@/components/app/Autocomplete";
import {endOfDay, isAfter, parse} from "date-fns";

export default {
  name: "ProfileChecks",
  components: {AppAutocomplete, AppDatePicker, AppBtn},
  props: {
    informationAmended: {
      type: Boolean
    },
    licenceInformationType: {
      type: String
    },
    licenceData: {
      type: Array
    },
    isEditable: {
      type: Boolean
    }
  },
  data() {
    return {
      dialog: false,
      selectedItem: null,
      selectedPerson: null,
      showDatePicker: false,
      selectedCompletionDate: null, //new Date().toISOString().substr(0, 10),
      vuexAction: null,
      items: [],
      listOfItems: [],
      addNewDetailsForm: null,
      headers: [
        {
          text: 'Group',
          align: 'start',
          sortable: false,
          value: 'groupId',
        },
        {text: 'Check', value: 'name'},
        {text: 'By', value: 'checkerPerson'},
        {text: 'Date Completed', value: 'completionDate'},
        {text: 'Expiry Date', value: 'expires'},
        {text: 'Status', value: 'status'},
        {text: 'Actions', value: 'actions'}
      ],

    }
  },
  computed: {
    ...mapState("app", ["loggedInUser"]),
    ...mapState("person", ["persons"]),
    ...mapState("group", ["groups"]),
    ...mapState("groupChecks", ["groupChecks", "currencyVerifiersList"]),

    scopedGroupChecks() {
      return this.groupChecks.filter(c => c.active = true)
    },
    scopedCurrencyVerifiersList() {
      return this.currencyVerifiersList ? this.currencyVerifiersList.filter(c => c.selectableByGroupMembers = true && c._id !== this.loggedInUser._id) : []
    },
    newItemCanSelfCertify() {
      if (!this.selectedItem) {
        return false
      }
      return this.items.find(item => item.name === this.selectedItem).canSelfCertify
    },

    newItemExpires() {
      if (!this.selectedItem) {
        return null
      }
      return this.items.find(item => item.name === this.selectedItem).expires
    },
    newItemGroupID() {
      if (!this.selectedItem) {
        return null
      }
      return this.items.find(item => item.name === this.selectedItem).groupId
    },

    newCheckId() {
      if (this.selectedItem && this.selectedCompletionDate) {
        const check = this.items.find(item => item.name === this.selectedItem)
        return check._id
      }
      return ''

    },

    formValid() {
      return this.selectedItem && this.selectedCompletionDate
    }
  },
  async created() {
    await this.setGroupChecks()
  },
  async mounted() {
    await this.setGroupChecks()
    this.items = this.groupChecks
    this.listOfItems = this.items
  },

  methods: {
    ...mapActions("groupChecks", ["setGroupChecks", "setCurrencyVerifiersList"]),
    ...mapActions("person", ["setPersons"]),
    getCurrencyVerifiersList() {
      this.setCurrencyVerifiersList(this.newItemGroupID)
    },
    add() {
      // Add entry
      if (this.$refs.formAmend.validate()) {
        // Format object to add depending if the item has an expiry date

        this.licenceData.push({
          groupId: this.newItemGroupID,
          name: this.selectedItem,
          groupCheckId: this.newCheckId,
          completionDate: this.selectedCompletionDate,
          checkerPerson: this.newItemCanSelfCertify ? 'Self' : this.getCheckerName(this.selectedPerson),
          checkerPersonId: this.newItemCanSelfCertify ? this.loggedInUser._id : this.selectedPerson,
        })
        this.$emit('commitChanges')


        // remove added item from dropdown
        if (!this.newItemExpires) {
          // this.items = this.items.filter(item => item.name !== this.selectedItem)
        }
        // Clear the form
        this.selectedCompletionDate = null
        this.selectedItem = null
      }
    },

    remove(item) {
      // Add remove Item
      // remove entry
      const index = this.licenceData.indexOf(item);
      this.licenceData.splice(index, 1);

      this.$emit('commitChanges')
    },

    getCheckerName(id) {
      const person = this.scopedCurrencyVerifiersList.find(p => p._id == id)
      return person ? person.name : 'Error'
    },
    getGroupName(id) {
      console.log(this.groups)
      const group = this.groups.find(g => g._id === id)
      return group.name
    },

    getExpiryDate(completionDate, groupCheckId) {
      if (!this.items) {
        return
      }
      const check = this.items.find(item => item._id === groupCheckId)
      if (!check) {
        return
      }

      if (check.expires) {
        return this.$moment(completionDate).endOf("day").add(check.validityNum, check.validityUnit).format('DD/MM/YYYY')
      }
      return 'Non Expiring'

    },

    itemExpires(id) {
      if (!this.items) {
        return
      }
      const check = this.items.find(item => item._id === id)
      if (!check) {
        return
      }
      return check.expires ? true : false
    },

    getItemStatusColor(id, completionDate) {
      const itemExpires = this.itemExpires(id)
      if (!itemExpires) return "green"
      const expiryDate = endOfDay(parse(this.getExpiryDate(completionDate, id), 'dd/MM/yyyy', new Date()))

      return isAfter(expiryDate, new Date()) ? "green" : "red"
    },


  }
}
</script>

<style scoped>

</style>